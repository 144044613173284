<template>
  <div
    class="header flex-space"
    v-for="(item, index) in fangquinfo.slice(0, 1)"
    :key="index"
    v-show="['/index', '/detail', '/drive'].includes(route.path)"
  >
    <div class="header-info flex">
      <span class="header-item"> {{ currentDate }}</span>

      <span class="header-item">{{ address }}</span>
    </div>
    <div class="header-text">
      <img src="@/assets/logo.png" alt="" class="header-img" />
      {{ title }}指挥中心
    </div>
    <div class="header-info2">
      <a href="#" class="header-a"
        ><img src="../assets/ico1.png" alt="" style="margin-right: 5px"
      /></a>
      后台管理
      <a href="#" class="header-a"><img src="../assets/log1.png" alt="" /></a>
    </div>
  </div>
  <router-view></router-view>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted, onUnmounted,reactive } from "vue";
import { Api } from "@/api";
import { useRoute } from "vue-router";
import { useCacheStore } from "../stores/cacheStore";
const cacheStore = useCacheStore();
const currentDate = ref(new Date());
const fangquinfo = ref([]);
const address=ref('');
const title=ref('');
const route = useRoute();
let fangquid = route.query.fangquid;
//==获取该防区地址和名称==
/* Api.getfangquinfo(fangquid).then((e) => {
  fangquinfo.value = (e ?? []).map((item) => {
    return {
      dizhi: item.dizhi ?? 0,
      name: item.name ?? 0,
    };
  });
}); */



//==获取该防区地址和名称 end==

const updateDate = () => {
  currentDate.value = new Date();
};

onMounted( async () => {
  updateDate(); // 初始化日期
  //==获取小区信息==
  const formData = reactive({
  	   "ids": fangquid,
  });
  const response = await axios.post(
    "https://chaojibaoan2.ec666.net/api/console/ConsoleDa?adminKey=longzongyyds",
    formData
  );
   fangquinfo.value = response.data.data.list;
	address.value=fangquinfo.value[0]['address'];
	title.value=fangquinfo.value[0]['title'];
	cacheStore.set('city', fangquinfo.value[0]['city']);
	cacheStore.set('suoshuxiaoqu', fangquinfo.value[0]['title']);
	//console.log('城市='+cacheStore.get('city'));
  //==获取小区信息 end==
  
  
 // setInterval(updateDate, 1000); // 每秒更新一次日期
});

onUnmounted(() => {
  clearInterval(updateDate); // 清除定时器
});
</script>

<style scoped>
.header {
  height: 81px;
  background: url(@/assets/bg.png) no-repeat bottom center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 30px;
  right: 0;
  padding-bottom: 20px;
  z-index: 20;
}
.header-text {
  font-size: 36px;
  font-weight: bold;
  width: 520px;
  text-align: center;
  padding-top: 6px;
  color: #fff;
}
.header-img {
  margin-right: 20px;
}
.header-info {
  flex: 1;
  align-items: center;
}
.header-info2 {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.header-item {
  margin-right: 30px;
}
.header-a {
  margin-left: 30px;
}
</style>
